<template>
	<w-layout fill-height>
		<MobileAppEditor v-if="selectedApplication" v-model="selectedApplication" :loading="loading" />
		<MobileAppListDetails v-else-if="applications.length" v-model="applications" />
		<MobileAppNoData v-else />
	</w-layout>
</template>

<script>
import Vue from 'vue'
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'
import HandleSideBarMixin from "@/mixins/SideBar/HandleSideBarMixin";
import MobileAppList from '@/components/SuperAdmin/MobileApp/MobileAppList'

export default {
	name: 'SuperAdminMobileAppManager',
	components: {
		MobileAppEditor: () => ({
			component: import('@/components/SuperAdmin/MobileApp/MobileAppEditor')
		}),
		MobileAppListDetails: () => ({
			component: import('@/components/SuperAdmin/MobileApp/MobileAppListDetails')
		}),
		MobileAppNoData: () => ({
			component: import('@/components/SuperAdmin/MobileApp/MobileAppNoData')
		})
	},
	mixins: [
		SuperAdminModuleGuard,
		HandleSideBarMixin,
	],
	data: function() {
		return {
			loading: false,
			blankApplication: null
		}
	},
	computed: {
		selectedApplication: {
			get: function () {
				return this.$store.state.mobileapps.selected
			},
			set: function (val) {
				this.$store.dispatch('mobileapps/setSelected', val)
			}
		},
		applications: {
			get: function () {
				return this.$store.state.mobileapps.list
			},
			set: function (list) {
				this.$store.dispatch('mobileapps/setList', list)
			}
		},
		accountingFirms: {
			get: function () {
				return this.$store.state.mobileapps.accountingFirms
			},
			set: function (list) {
				this.$store.dispatch('mobileapps/setAccountingFirms', list)
			}
		}
	},
	watch: {
		loading: {
			handler: function () {
				this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, this.loading)
			}
		}
	},
	created: function () {
		this.loading = true
		Promise.all([this.loadAccountingFirmList(), this.loadApplicationList(), this.init()]).finally(() => {
			this.loading = false
		})
	},
	destroyed: function () {
		this.appEventBus.emit(this.appEvents.CLEAR_SIDEBAR_CONTENT, this)
		this.$store.dispatch('mobileapps/reset')
		this.setHasSidebar(false)
		this.setPermanentSidebar(false)
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [
				{ event: this.events.SELECTED_MOBILE_APP, action: this.selectMobileApp },
				{ event: this.events.CLEAR_SELECTED_MOBILE_APP, action: this.clearSelectedMobileApp },
				{ event: this.events.CREATE_NEW_MOBILE_APP, action: this.createNewMobileApp },
				{ event: this.events.CREATE_MOBILE_APP, action: this.createMobileApp },
				{ event: this.events.UPDATE_MOBILE_APP, action: this.updateMobileApp },
				{ event: this.events.DELETE_MOBILE_APP, action: this.deleteMobileApp },
				{ event: this.events.RESET_MOBILE_APP_LIST, action: this.resetMobileAppList },
				{ event: this.events.LOAD_MOBILE_APP_LIST, action: this.loadApplicationList },
				{ event: this.events.UPGRADE_MOBILE_APP, action: this.upgradeMobileApp },
				{ event: this.events.UPGRADE_MOBILE_APPS, action: this.upgradeMobileApps },
				{ event: this.events.UPGRADE_AND_UPDATE_MOBILE_APP, action: this.upgradeAndUpdateMobileApp }
			]
		},
		init: function () {
			const MobileAppListClass = Vue.extend(MobileAppList)
			const mobileAppListComponent = new MobileAppListClass({
				parent: this
			})
			this.appEventBus.emit(this.appEvents.SET_SIDEBAR_CONTENT, {
				canMinimize: this.$vuetify.breakpoint.mdAndUp,
				canClose: this.$vuetify.breakpoint.smAndDown,
				title: this.$t('mobileapp.name'),
				moduleInstance: mobileAppListComponent,
				emitter: this
			})
			this.setHasSidebar(true)
			this.setPermanentSidebar(true)
		},
		loadAccountingFirmList: function () {
			return this.service.getAccountingFirmList().then(list => this.$store.dispatch('mobileapps/setAccountingFirms', list))
		},
		loadApplicationList: function () {
			return this.service.listMobileApp().then(list => (this.applications = list))
		},
		resetMobileAppList: function () {
			return this.service.listMobileApp(true).then(list => (this.applications = list))
		},
		loadApplication: function (mobileAppId) {
			let result = Promise.resolve()
			if (mobileAppId !== null) {
				result = this.service.getMobileApp(mobileAppId)
			}
			return result
		},
		selectMobileApp: function (mobileAppId, force = false) {
			this.loading = true
			let result = Promise.resolve()
			const alreadyLoaded = this.applications.filter(application => application.id == mobileAppId)
			if (force || alreadyLoaded.length != 1) {
				result = this.loadApplication(mobileAppId).then(application => {
					this.selectedApplication = application
				})
			} else {
				this.selectedApplication = alreadyLoaded[0]
			}
			return result.finally(() => (this.loading = false))
		},
		resetBlankMobileApp: function () {
			this.blankApplication = {
				logo: null,
				logo_file_path: '',
				color: '',
				title: '',
				subtitle: '',
				promotional_text: '',
				description: this.$t('mobileapp.defaults.description'),
				keywords: this.$t('mobileapp.defaults.keywords'),
				accounting_firms: [],
				bundle_id: '',
				app_store_id: '',
				app_store_url: '',
				marketing_url: '',
				support_url: 'https://support.welyb.fr',
				play_store_url: ''
			}
		},
		clearSelectedMobileApp: function () {
			this.selectedApplication = null
		},
		createNewMobileApp: function () {
			this.resetBlankMobileApp()
			this.selectedApplication = this.blankApplication
		},
		createMobileApp: function () {
			this.loading = true
			return this.service
				.createMobileApp(this.selectedApplication)
				.then(newApplication => this.loadApplicationList().then(() => this.selectMobileApp(newApplication.id)))
				.finally(() => {
					this.eventBus.emit(this.events.MOBILE_APP_CREATED)
					this.loading = false
				})
		},
		updateMobileApp: function () {
			this.loading = true
			return this.service
				.updateMobileApp(this.selectedApplication)
				.then(newApplication => this.loadApplicationList().then(() => this.selectMobileApp(newApplication.id)))
				.finally(() => (this.loading = false))
		},
		deleteMobileApp: function (mobileAppId) {
			this.loading = true
			return this.service
				.deleteMobileApp(mobileAppId)
				.then(() => this.loadApplicationList())
				.finally(() => (this.loading = false))
		},
		upgradeMobileApp: function (mobileAppId) {
			this.loading = true
			return this.service
				.upgradeMobileApp(mobileAppId)
				.then(this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('mobileapp.messages.upgrade_success')))
				.finally(() => (this.loading = false))
		},
		upgradeMobileApps: function () {
			this.loading = true
			return this.service
				.upgradeMobileApps()
				.then(this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('mobileapp.messages.upgrade_all_success')))
				.then(this.loadApplicationList)
				.finally(() => (this.loading = false))
		},
		upgradeAndUpdateMobileApp: function (mobileAppId) {
			this.loading = true
			return this.service
				.upgradeAndUpdateMobileApp(mobileAppId)
				.then(this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('mobileapp.messages.upgrade_and_update_success')))
				.finally(() => (this.loading = false))
		}
	}
}
</script>
